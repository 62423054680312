<template>
  <div class="reportAdd">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="90px" size="small">
          <el-form-item label="报告名称:" prop="reportName">
            <el-input type="text" v-model="inform.reportName" placeholder="请输入报告名称"></el-input>
          </el-form-item>
          <el-form-item label="报告类型:" prop="reportType">
            <el-select v-model="inform.reportType" placeholder="报告类型" @change="changeType">
              <el-option v-for="(item,index) in dicObj.typeData" :key="index" :label="item.dictName" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="报告周期:" prop="reportTime">
            <el-date-picker
              v-if="inform.reportType!='3'"
              v-model="inform.reportTime" 
              :type="inform.reportType=='0'?'date':
              inform.reportType=='1'?'week':
              inform.reportType=='2'?'month':'year'" 
              :format="inform.reportType=='1'?'yyyy 第 WW 周':''"
              placeholder="选择周期"></el-date-picker>
            <el-select v-else v-model="inform.reportTime" placeholder="选择周期">
              <el-option label="第一季度" value="1"></el-option>
              <el-option label="第二季度" value="2"></el-option>
              <el-option label="第三季度" value="3"></el-option>
              <el-option label="第四季度" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="查找范围:" prop="queryScope">
            <el-radio-group v-model="inform.queryScope">
              <el-radio :label="0">全部场所</el-radio>
              <el-radio :label="1">全部区域</el-radio>
              <el-radio :label="2">当前场所</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="场所" prop="homeId" v-if="inform.queryScope==2">
            <el-select v-model="inform.homeId" placeholder="请选择场所">
              <el-option v-for="(item,index) in homeList" :key="index" :label="item.homeName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分页标题:" prop="footerName">
            <el-input type="text" v-model="inform.footerName" placeholder="请输入分页标题"></el-input>
          </el-form-item>
         
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl,globalStr } from '@/assets/js/api'
import qs from 'qs'
import {delChildren,formatTime} from '@/utils/utils'
var vm;
export default {
  props:['dicObj'],
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度
      homeList:[],//场所列表
      //form信息
      inform :{
        id:'',
        reportName:'',//报告名称
        reportType:'',//报告类型
        footerName:'',//页脚名称
        reportTime:'',//报告周期
        queryScope:'',//查询范围 0：全部场所，1：全部区域
        homeId:'',//场所id
      },

      rules :{
        reportName: [
          { required: true, message: '请输入报告名称', trigger: 'blur' },
        ],
        reportType: [
          { required: true, message: '请选择报告类型', trigger: 'change' },
        ],
        reportTime: [
          { required: true, message: '请选择报告周期', trigger: 'change' },
        ],
        queryScope: [
          { required: true, message: '请选择查询范围', trigger: 'change' },
        ],
        homeId: [
          { required: true, message: '请选择场所', trigger: 'change' },
        ],
      },
    }
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '500px'
      vm.isDio = true
      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '生成报告'
        }
      })
      vm.getHome();
    },
    //类型改变
    changeType(val){
      vm.inform.reportTime = ''
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.reportOverview()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.isDio = false
    },
    //新增
    async reportOverview(){
      let now = new Date();
      let time = ''
      if(vm.inform.reportType=='3'){
        if(vm.inform.reportTime =='1') time = `${now.getFullYear()}-01-01`
        if(vm.inform.reportTime =='2') time = `${now.getFullYear()}-04-01`
        if(vm.inform.reportTime =='3') time = `${now.getFullYear()}-07-01`
        if(vm.inform.reportTime =='4') time = `${now.getFullYear()}-10-01`
      }
      let dataObj = {
        'areaId':vm.$store.state.csType,
        'reportName':vm.inform.reportName,//报告名称
        'reportType':vm.inform.reportType,//报告类型
        'queryScope':vm.inform.queryScope,//查找范围
        'footerName':vm.inform.footerName,//页脚名称
        'reportTime':vm.inform.reportType=='3'? time: vm.inform.reportTime,//报告名称
        'homeId':vm.inform.homeId//场所id
      }
      const res = await apiUrl.reportOverview(dataObj)
      if(res?.code==200){
        vm.$message.success('生成成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取场所
    async getHome(){
      let dataObj = {
        'areaId': this.$store.state.csType
      }
      const res = await apiUrl.getHome(qs.stringify(dataObj))
      if(res?.code == 200){
        this.homeList = res.data
      }
    },
  }
}
</script>
<style lang='scss'>
.reportAdd{
  .contDio{
    max-height: 500px;
    overflow: auto;
  }
}
</style>