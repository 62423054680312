<template>
  <div class='safety-scene mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name"><div>检索条件</div></div>
      <el-form ref="searchForm" :model="keyWord" label-width="70px" inline @submit.native.prevent size="small">
        <el-form-item label="名称:" prop="name">
          <el-input type="text" class="w180" v-model="keyWord.name" placeholder="请输入报告名称" clearable @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="时间:" prop="time">
          <el-date-picker v-model="keyWord.time" value-format="yyyy-MM-dd HH:mm:ss" clearable :default-time="['00:00:00', '23:59:59']" @change="getList(1)" type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"/>
        </el-form-item>
        <el-form-item label="类型:" prop="type">
          <el-select v-model="keyWord.type" placeholder="类型" class="w100" clearable @change="getList(1)">
            <el-option v-for="(item,index) in dicObj.typeData" :key="index" :label="item.dictName" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="pageCont">
      <div class="title_name">
        <div>列表详情</div>
        <div>
          <el-button type="text" @click="openDio('add')" v-if="isAuth('safe:safety:electricityReport:add')"><i class="el-icon-circle-plus-outline"></i>生成报告</el-button>
           <el-button type="text" class="error" @click="batchDel()" v-if="isAuth('safe:safety:electricityReport:delete')"><i class="el-icon-delete"></i>批量删除</el-button>
        </div>
      </div>
      <div class="tableDivPage mt10">
        <el-table class="tableCont" :data="tableData" stripe :height="tableH" style="width:100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" fixed></el-table-column>
          <el-table-column prop="reportName" label="报告名称" width="200" show-overflow-tooltip/>
          <el-table-column prop="reportNo" label="报告编号" show-overflow-tooltip/>
          <el-table-column prop="reportTypeName" label="报告类型"/>
          <el-table-column prop="reportTimeStr" label="报告周期" width="200"/>
          <el-table-column prop="createAccount" label="创建人"/>
          <el-table-column :formatter="formatTime" label="创建时间"/>
          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" class="primary" v-if="isAuth('safe:safety:electricityReport:preview')" @click="openDio('preview',scope.row)"><i class="el-icon-view"></i>预览</el-button>
              <el-button type="text" class="error" @click="openDio('del',scope.row)" v-if="isAuth('safe:safety:electricityReport:delete')"><i class="el-icon-delete"></i>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pageDiv">
        <el-pagination
          :current-page="curr"
          :page-size="limits"
          background
          layout="total,sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100,200]"
          :total="total"
          @size-change="sizeChange"
          @current-change="currChange"
        />
      </div>
    </div>

    <!--生成报告-->
    <report-add ref="addDio" :dicObj="dicObj" @addEditSucc="getList()"></report-add>

    <!--预览-->
    <report-preview ref="previewDio" :dicObj="dicObj" @addEditSucc="getList()"></report-preview>

  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import reportAdd from './report-add.vue'
import reportPreview from './report-preview.vue'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
var vm;
export default {
  name:'safety-scene',
  data() {
    return {
      //筛选数据
      keyWord :{
        name:'',
        time:'',
        type:'',
      },
      dicObj:{
        typeData:[],//类型数据
      },
      //分页
      curr :1,//当前页
      limits :10,//每页容量
      total :0,//数据总数
      
      tableH :'',//表格高度
      tableActive:[],//表格选中
      tableData :[],

      isAdmin:false,//是否是超级管理员
    }
  },
  components:{
    reportPreview,
    reportAdd,
    breadCrumb,
  },
  watch:{
    '$store.state.homeId':function(newVal){
      if(newVal!=='0'){
        vm.getList(1)
      }
    }
  },
  created(){
    vm = this
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if(userInfo){
      this.isAdmin = userInfo.isAdmin
    }
    vm.getCurrDics()
  },
  mounted(){
    if(vm.$store.state.homeId!=null) vm.getList()
    
    window.onresize=() => {
      vm.initHeight()
    }
    
  },

  //方法
  methods:{
    //表格多选
    handleSelectionChange(val) {
      vm.tableActive = val;
    },
    formatTime(row,column){
      return row.createTime?formatTime(row.createTime,'Y-M-D h:m:s'):'-'
    },
    //打开弹窗
    openDio (type,item){
      if(type == 'del'){
        vm.$confirm('确定删除该选项','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            let arr=[]
            if(item){
              arr.push(item.id)
            }
            vm.deleteReport(arr)
          })
          .catch(() => {})
      }else{
        if(type == 'add'){
          vm.$refs.addDio.init(type,item);//生成报告
        }else if(type == 'preview'){
          vm.$refs.previewDio.init(type,item);//预览
        }
      }
    },
    //批量删除
    batchDel(){
      if(vm.tableActive.length==0){
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = []
        for(var i in vm.tableActive){
          arr.push(vm.tableActive[i].id)
        }
        vm.deleteReport(arr);//删除
      }).catch(() => {});
    },
    //删除
    async deleteReport(ids){
      let dataObj = {
        'data':ids
      }
      const res = await apiUrl.deleteReport(dataObj)
      if(res?.code==200){
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //分页选择
    sizeChange (val){
      vm.limits = val
      vm.getList(vm.curr,val)
    },
    currChange (val){
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList (_curr,_limits){
      let dataObj = {
        "areaId": vm.$store.state.csType,
        "homeId": vm.$store.state.homeId,
        'pageNo': _curr?_curr:vm.curr,
        'pageSize': _limits?_limits:vm.limits,
        "reportName": vm.keyWord.name,
        "reportType": vm.keyWord.type,
        "reportStartTime": vm.keyWord.time?vm.keyWord.time[0]:'',
        "reportEndTime": vm.keyWord.time?vm.keyWord.time[1]:'',
      }
      const res = await apiUrl.reportListPage(qs.stringify(dataObj))
      if(res?.code==200){
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr?_curr:vm.curr
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取数据字典
    async getCurrDics(){
      let dataObj = ['REPORT_TYPE']
      const res = await apiUrl.getDicts(dataObj)
      if(res?.code==200){
        vm.dicObj.typeData = res.data.REPORT_TYPE||[];//报告类型
      }else{
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight (){
      var minWin = window.innerHeight;
      vm.tableH = minWin-322
    }
  }
}
</script>
<style lang='scss'>
.safety-scene{

}
</style>
