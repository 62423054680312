<template>
  <div class="reportPreview">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" fullscreen :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.6)">
        <div style="min-height: calc(100% - 180px);">
          <pdf ref="pdf" v-if="pdfUrl!=''" v-show="loading==false" :src="pdfUrl" v-for="(item,index) in totalPages" :key="index" :page="index+1" @loaded="onPdfLoaded"></pdf>
        </div>
        <el-form class="mt20" ref="dioform" :rules="rules" :model="inform" label-width="90px" size="small">
          <el-form-item label="诊断意见:" prop="diagnosis">
            <el-input type="textarea" :rows="6" v-model="inform.diagnosis" placeholder="请输入诊断意见"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button @click="checkForm('dioform')" type="warning" size="small">提交诊断意见</el-button>
        <!-- <el-button type="primary" size="small" v-print="'#printMe'">打印</el-button> -->
        <el-button type="primary" size="small" @click="printFun()">打印</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import { apiUrl,globalStr } from '@/assets/js/api'
import qs from 'qs'
import {delChildren,formatTime} from '@/utils/utils'
var vm;
export default {
  props:['dicObj'],
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        diagnosis:'',//诊断意见
      },
      rules :{
        diagnosis: [
          { required: true, message: '诊断意见不能为空', trigger: 'blur' },
        ],
      },

      pdfUrl: '',
      totalPages: 1, // 页码总数
      currPage:0,

      loading:false,
    }
  },
  components:{
    pdf,
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '800px'
      vm.isDio = true
      vm.loading = true
      vm.pdfUrl = `${globalStr}/fileView${item.fileName}`
      vm.getNumPages()
      vm.$nextTick(() => {
        if(type == 'preview'){
          vm.dioTit = '预览用电报告'
          vm.inform.id = item.id
          vm.inform.diagnosis = item.diagnosisText
          //获取pdf总页数
          
        }
      })
    },
    //获取pdf总页数
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(vm.pdfUrl);
      loadingTask.promise.then((pdf) => {
        console.log('pdf',pdf);
        vm.totalPages = pdf.numPages;
      }).catch((error) => {
        console.error('pdf加载失败', error);
      })
    },
    onPdfLoaded(e) {
      vm.currPage+=1
      console.log(vm.currPage,vm.totalPages);
      if (vm.currPage!=1&&vm.currPage >= vm.totalPages) {
        vm.loading = false;
      }
    },
    //打印
    printFun() {
      const win = window.open(this.pdfUrl, '_blank');
      if (win) {
        // 确保PDF已经加载完成
        win.onload = function() {
          win.print();
        };
      }
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          vm.reportUpdate()
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      // vm.inform.roleIds = []
      vm.pdfUrl = '';
      vm.totalPages = 1 // 页码总数
      vm.currPage = 0
      vm.loading=false
      vm.isDio = false
    },
    //提交诊断报告
    async reportUpdate(){
      let dataObj = {
        'id':vm.inform.id,
        'diagnosis':vm.inform.diagnosis,//意见
      }
      const res = await apiUrl.reportUpdate(dataObj)
      if(res?.code==200){
        vm.$message.success('诊断意见提交成功！')
        let url = JSON.parse(JSON.stringify(vm.pdfUrl))
        vm.pdfUrl = ''
        vm.currPage = 0
        vm.totalPages = 1
        vm.loading = true
        setTimeout(() => {
          vm.pdfUrl = url
          vm.getNumPages()
        }, 1000);

        
        // vm.$emit('addEditSucc')
        // vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
  }
}
</script>
<style lang='scss'>
.reportPreview{
  .contDio{
    height: calc(100vh - 122px);
    overflow: auto;
    position: relative;
  }
}
</style>